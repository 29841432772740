import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ProgressSpinnerComponent } from './spinner/spinner.module';
import { ToastShowComponent } from './spinner/toast.module';
import { L10n } from '@syncfusion/ej2-base';
import { Meta } from '@angular/platform-browser';
import { UrlService } from './service/url.service';
import { DOCUMENT, Location, isPlatformBrowser } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { DataService } from './service/data.service';

L10n.load({
  fr: {
    grid: {
      EmptyRecord: 'Aucun enregistrement affiché'
    },
    pager: {
      currentPageInfo: '{0} de {1} pages',
      pagerDropDown: 'objets par page',
      pagerAllDropDown: 'objets',
      totalItemsInfo: '	({0} objets)',
      totalItemInfo: '	({0} objet)'
    },
    daterangepicker: {
      placeholder: 'Choisir une date',
      startLabel: 'Date de départ',
      endLabel: 'Date de fin',
      applyText: 'Appliquer',
      cancelText: 'Annuler',
      selectedDays: 'Jours sélectionnés',
      days: 'jours',
      customRange: 'date personnalisée'
    }
  },
  en: {
    grid: {
      EmptyRecord: 'No records displayed'
    },
    pager: {
      currentPageInfo: '{0} of {1} pages',
      pagerDropDown: 'items by page',
      pagerAllDropDown: 'items',
      totalItemsInfo: '	({0} items)',
      totalItemInfo: '	({0} item)'
    },
    daterangepicker: {
      placeholder: 'Select a date',
      startLabel: 'Start date',
      endLabel: 'End date',
      applyText: 'Apply',
      cancelText: 'Cancel',
      selectedDays: 'Selected days',
      days: 'days',
      customRange: 'custom range'
    }
  }
});
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [ProgressSpinnerComponent, ToastShowComponent, RouterModule]
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];
  title = 'zipplex';
  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private ssrCookieService: SsrCookieService,
    private router: Router,
    private gaService: GoogleAnalyticsService,
    private metaTagService: Meta,
    private urlService: UrlService,
    private location: Location,
    private dataService: DataService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const name =
          event.urlAfterRedirects === '/'
            ? ''
            : event.urlAfterRedirects.substring(1);
        this.gaService.pageView(event.urlAfterRedirects, name);
      }
    });
  }

  ngOnInit() {
    let lang = '';
    let aspNetCulture = '';
    if (isPlatformBrowser(this.platformId)) {
      aspNetCulture = this.cookieService.get('.AspNetCore.Culture');
    } else {
      aspNetCulture = this.ssrCookieService.get('.AspNetCore.Culture');
    }

    if (aspNetCulture) {
      lang = aspNetCulture.substring(2, 4);
      if (lang !== 'en' && lang !== 'fr') lang = 'fr';
      const url = this.location.path();

      if (url.startsWith('/en')) lang = 'en';
    } else {
      this.translate.addLangs(['fr', 'en']);
      this.translate.setDefaultLang('fr');
      const browserLang = this.translate.getBrowserLang();
      const url = this.location.path();

      if (url.startsWith('/en')) lang = 'en';

      if (lang === '') lang = browserLang?.match(/en|fr/) ? browserLang : 'fr';
    }
    this.translate.use(lang);
    if (this.location.path() === '') {
    } else {
      if (isPlatformBrowser(this.platformId)) {
        this.cookieService.set(
          '.AspNetCore.Culture',
          'c=' + lang + '|uic=' + lang,
          {
            expires: 7,
            path: '/',
            domain: null,
            secure: true,
            sameSite: 'Strict'
          }
        );
      }
    }

    this.urlService.setLangLoad(lang);

    if (environment.cookieeyes) {
      const script = document.createElement('script');
      script.id = 'cookieyes';
      script.type = 'text/javascript';
      script.src =
        'https://cdn-cookieyes.com/client_data/e7dcb442c41ebea6131469a4/script.js';
      document.head.appendChild(script);
    }

    if (environment.hideGoogleIndex)
      this.metaTagService.addTags([{ name: 'robots', content: 'noindex' }]);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((s) => s.unsubscribe());
  }
}
