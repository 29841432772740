import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    if (environment.production) {
      Sentry.init({
        dsn: 'https://fac28b7e04944f23a06be91e6c6684b5@sentry.io/1532174',
        maxValueLength: 100000,
        beforeSend(event) {
          // Modify the event here6
          try {
            console.log("************" + event["exception"]["values"][0]["value"] + "************");
            console.log("************" + event["exception"]["values"][0]["stacktrace"]["frames"][event["exception"]["values"][0]["stacktrace"]["frames"].length - 1]["filename"] + "************");
            return event;
          } catch {
            return event;
          }
        },
        tracesSampleRate: environment.tracesSampleRate, // We recommend adjusting this in production
        replaysSessionSampleRate: environment.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: environment.replaysOnErrorSampleRate // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  }

  handleError(error) {
    Sentry.captureException(error.originalError || error);
  }
}
