<!-- <ejs-toast #toasttype [position]='position' [showProgressBar]="showProgressBar"></ejs-toast> -->
<ejs-toast #toasttype [position]='position' [showProgressBar]="showProgressBar" >
  <ng-template #template>
    <div [ngClass]="dataToast.cssClass ? 'customToast ' + dataToast.cssClass  : 'customToast' "  >
      <div class="wrapIcon" >
        <img src="/assets/img/ic_toast_success.png" class="icon iconToastSuccess" alt="{{'zipplex' | translate}}">
        <img src="/assets/img/ic_toast_error.png" class="icon iconToastError" alt="{{'zipplex' | translate}}">
        <img src="/assets/img/ic_toast_warning.png" class="icon iconToastInfo" alt="{{'zipplex' | translate}}">
      </div>
      <div class="wrapInfo">
        @if (title) {
          <p class="titleToast"  >
            {{ title }}
          </p>
        }
        @if (content) {
          <p class="contentToast"  >
            {{ content }}
          </p>
        }
      </div>
      <button id="buttonClose" class="btnCloseToast" (click)="closeToast()"
              [attr.aria-label]="('Zipplex' | translate) + ' ' + ('close' | translate)">
        <i class="material-icons">{{ 'close' | translate}}</i>
      </button>
    </div>
  </ng-template>
</ejs-toast>
