import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { take } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UrlService {
  private static url: string;
  private static switchLang: boolean;

  private unsubscribe: Subscription[] = [];
  public urlLang = '';

  public login = '/en/login';
  public register = '/en/register';
  public forgotPassword = '/en/forgot-password';
  public confidentiality = '/en/confidentiality';
  public terms = '/en/terms';
  public baseUrl = '/en';
  public news = '/en/news';
  public newsDetails = '/en/news-details';
  public subscriptionDetails = '/en/subscription-details';
  public dashboard = '/en';
  public research = '/en/research';
  public buildings = '/en/buildings';
  public alerts = '/en/alerts';
  public backup = '/en/backups';
  public backupV1 = '/en/backup-v1';
  public calculator = '/en/calculator';
  public calculatorScenario = '/en/calculator/scenario';
  public calculatorHome = '/en/calculator/home';
  public map = '/en/map';
  public summaryResult = '/en/summaryResult';
  public googleMap = '/en/google-map';
  public library = '/en/library';
  public partner = '/en/partner';
  public subscription = '/en/subscription';
  public profil = '/en/profil';
  public changePassword = '/en/change-password';
  public result = '/en/result';
  public resetPassword = '/en/reset-password';

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document
  ) {}

  SetLang(language: string) {
    this.unsubscribe.push(
      this.translateService.use(language).subscribe({
        next: (val) => {
          const lang = this.translateService.currentLang;
          if (!lang) return;
          if (lang === 'en') this.urlLang = '/en';
          else this.urlLang = '';
          let url = '';
          if (lang === 'fr') {
            this.baseUrl = '/';
            this.terms = '/termes';
            this.login = '/connexion';
            this.register = '/enregistrement';
            this.forgotPassword = '/mot-de-passe-oublie';
            this.resetPassword = '/reinitialiser-le-mot-de-passe';
            this.confidentiality = '/confidentialite';
            this.news = '/nouvelles';
            this.newsDetails = '/nouvelles-details';
            this.subscriptionDetails = '/abonnement-details';
            this.dashboard = '/';
            this.research = '/recherche';
            this.buildings = '/batiments';
            this.alerts = '/alertes';
            this.backupV1 = '/sauvegarde-v1';
            this.backup = '/sauvegarde';
            this.calculator = '/calculateur';
            this.map = '/carte';
            this.googleMap = '/google-map';
            this.library = '/librairie';
            this.partner = '/partenaire';
            this.subscription = '/abonnement';
            this.profil = '/profil';
            this.changePassword = '/changer-mot-de-passe';
            this.result = '/resultat';
            this.calculatorScenario = '/calculateur/scenario';
            this.calculatorHome = '/en/calculateur/accueil';
            this.summaryResult = '/en/summaryResult';
          } else {
            this.baseUrl = '/en';
            this.login = '/en/login';
            this.register = '/en/register';
            this.forgotPassword = '/en/forgot-password';
            this.resetPassword = '/en/reset-password';
            this.confidentiality = '/en/confidentiality';
            this.terms = '/en/terms';
            this.news = '/en/news';
            this.newsDetails = '/en/news-details';
            this.subscriptionDetails = '/en/subscription-details';
            this.dashboard = '/en/dashboard';
            this.research = '/en/research';
            this.buildings = '/en/buildings';
            this.alerts = '/en/alerts';
            this.backupV1 = '/en/backup-v1';
            this.backup = '/en/backup';
            this.calculator = '/en/calculator';
            this.map = '/en/map';
            this.googleMap = '/en/google-map';
            this.library = '/en/library';
            this.partner = '/en/partner';
            this.subscription = '/en/subscription';
            this.profil = '/en/profil';
            this.changePassword = '/en/change-password';
            this.result = '/en/result';
            this.calculatorScenario = '/en/calculator/scenario';
            this.calculatorHome = '/en/calculator/home';
            this.summaryResult = '/en/resultatSommaire';
          }
          if (
            UrlService.url.includes('/terms') ||
            UrlService.url.includes('/termes')
          )
            url = this.terms;
          else if (
            UrlService.url.includes('/confidentiality') ||
            UrlService.url.includes('/confidentialite')
          )
            url = this.confidentiality;
          else if (
            UrlService.url.includes('/register') ||
            UrlService.url.includes('/enregistrement')
          )
            url = this.register;
          else if (
            UrlService.url.includes('/forgot-password') ||
            UrlService.url.includes('/mot-de-passe-oublie')
          )
            url = this.forgotPassword;
          else if (
            UrlService.url.includes('/reset-password') ||
            UrlService.url.includes('/reinitialiser-le-mot-de-passe')
          ) {
            url = this.resetPassword;
          } else if (
            UrlService.url.includes('/login') ||
            UrlService.url.includes('/connexion')
          )
            url = this.login;
          else if (
            UrlService.url.includes('/news-details') ||
            UrlService.url.includes('/nouvelles-details')
          ) {
            const id = UrlService.url.substring(
              UrlService.url.lastIndexOf('/') + 1
            );
            url = `${this.newsDetails}/${id}`;
          } else if (
            UrlService.url.includes('/subscription-details') ||
            UrlService.url.includes('/abonnement-details')
          ) {
            const id = UrlService.url.substring(
              UrlService.url.lastIndexOf('/') + 1
            );
            url = `${this.subscriptionDetails}/${id}`;
          } else if (
            UrlService.url.includes('/news') ||
            UrlService.url.includes('/nouvelles')
          )
            url = this.news;
          else if (UrlService.url === '/' || UrlService.url === '/en')
            url = this.dashboard;
          else if (
            UrlService.url.includes('/research') ||
            UrlService.url.includes('/recherche')
          )
            url = this.research;
          else if (
            UrlService.url.includes('/buildings') ||
            UrlService.url.includes('/batiments')
          )
            url = this.buildings;
          else if (
            UrlService.url.includes('/alerts') ||
            UrlService.url.includes('/alertes')
          )
            url = this.alerts;
          else if (
            UrlService.url.includes('/backup-v1') ||
            UrlService.url.includes('/sauvegarde-v1')
          )
            url = this.backupV1;
          else if (
            UrlService.url.includes('/backup') ||
            UrlService.url.includes('/sauvegarde')
          )
            url = this.backup;
          else if (
            UrlService.url.includes('/calculator/scenario') ||
            UrlService.url.includes('/calculateur/scenario')
          ) {
            const pos = UrlService.url.lastIndexOf('/');
            const id = UrlService.url.substring(pos, UrlService.url.length);
            url = this.calculatorScenario + '/' + id;
          } else if (
            UrlService.url.includes('/calculator/home') ||
            UrlService.url.includes('/calculateur/accueil')
          )
            url = this.calculatorHome;
          else if (
            UrlService.url.includes('/calculator') ||
            UrlService.url.includes('/calculateur')
          )
            url = this.calculator;
          else if (
            UrlService.url.includes('/map') ||
            UrlService.url.includes('/carte')
          )
            url = this.map;
          else if (UrlService.url.includes('/google-map')) url = this.googleMap;
          else if (
            UrlService.url.includes('/library') ||
            UrlService.url.includes('/librairie')
          )
            url = this.library;
          else if (
            UrlService.url.includes('/partner') ||
            UrlService.url.includes('/partenaire')
          )
            url = this.partner;
          else if (
            UrlService.url.includes('/subscription') ||
            UrlService.url.includes('/abonnement')
          )
            url = this.partner;
          else if (
            UrlService.url.includes('/profil') ||
            UrlService.url.includes('/profile')
          )
            url = this.profil;
          else if (
            UrlService.url.includes('changer-mot-de-passe') ||
            UrlService.url.includes('change-password')
          )
            url = this.changePassword;
          else if (
            UrlService.url.includes('/result') ||
            UrlService.url.includes('/resultat')
          )
            url = this.result;
          else if (UrlService.url === '' || UrlService.url === '/en')
            url = this.baseUrl;
          if (UrlService.switchLang && url != UrlService.url) {
            this.activatedRoute.queryParams
              .pipe(take(1))
              .subscribe((params) => {
                this.router.navigate([`/${url}`], {
                  queryParamsHandling: 'preserve'
                });
              });

            UrlService.switchLang = false;
          }

          this.cookieService.set(
            '.AspNetCore.Culture',
            'c=' +
              this.translateService.currentLang +
              '|uic=' +
              this.translateService.currentLang,
            {
              expires: 7,
              path: '/',
              domain: null,
              secure: true,
              sameSite: 'Strict'
            }
          );
          if (this.authenticationService.getJwt() !== '') {
            this.userService
              .setLanguage(this.translateService.currentLang)
              .subscribe();
          }
        },
        complete: () => {
          this.unsubscribe.forEach((c) => c.unsubscribe());
          this.unsubscribe = [];
        }
      })
    );
  }

  switchLang(lang: string = null) {
    let language;
    if (!lang) {
      language = 'fr';
      if (this.translateService.currentLang === 'fr') {
        language = 'en';
      }
    } else language = lang;

    let url = this.location.path();
    if (url.startsWith('/en')) url = url.substring(3);
    else if (url === '/') url = '';

    UrlService.url = url;
    UrlService.switchLang = true;
    this.SetLang(language);

    if (isPlatformBrowser(this.platformId)) {
      this.document.documentElement.lang = language;
    }
  }

  setLangLoad(lang: string) {
    const locationURL = this.location.path().split('?')[0];

    UrlService.url = locationURL;
    UrlService.switchLang = true;

    this.SetLang(lang);
  }

  getUrlMenu(id: string) {
    if (id === 'dashboard') return this.dashboard;
    else if (id === 'research') return this.research;
    else if (id === 'buildings') return this.buildings;
    else if (id === 'alerts') return this.alerts;
    else if (id === 'backup-v1') return this.backupV1;
    else if (id === 'backups') return this.backup;
    else if (id === 'news') return this.news;
    else if (id === 'calculator') return this.calculator;
    else if (id === 'map') return this.map;
    else if (id === 'google-map') return this.googleMap;
    else if (id === 'library') return this.library;
    else if (id === 'partner') return this.partner;
    else if (id === 'subscription') return this.subscription;
    else if (id === 'profil') return this.profil;
    else if (id === 'confidentiality') return this.confidentiality;
  }
}
