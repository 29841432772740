export const environment = {
  apiUrl: 'https://localhost:44328',
  //apiUrl: "https://192.168.1.8:44328",
  production: false,
  cookieeyes: false,
  googleKey: 'AIzaSyAjUHOfN4QnfpX-uodUO1K-jMbvF_Zc6qU',
  cookieDomain: 'localhost',
  stripeKey:
    'pk_test_51HKOw3AvLOeXTgkLgOdIPwF5QbtYAaXePSMBp01G94HRjI6gTxbgnYzcwfmkonsUobN3PP3bMNiZiaJEFDuKjBIa005uFOgEwd', // "pk_test_H1dKUc28xO338NhogJ1qHBqD008eTqDPRN"
  googleAnalytics: '',
  powerBi: 'test',
  mapbox:
    'pk.eyJ1IjoiZnJlZGVyaWNjb3V0dSIsImEiOiJjbGRhMzdtMDAwZXNnM29tbXVpaWZiNjJhIn0.Mx3glQKTKPjITZTOyx9DkA',
  tracing: true,
  sendToSegment: false,
  hideGoogleIndex: true,
  jwtName: 'jwtdev',
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0
};
