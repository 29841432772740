import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SocialUser } from '@abacritt/angularx-social-login';

@Injectable({ providedIn: 'root' })
export class SignInService {
  constructor(private http: HttpClient) {}

  PasswordSignIn(email: string, password: string): Observable<object> {
    return this.http
      .post('/api/SignIn/PasswordSignIn', { email: email, password: password })
      .pipe(map((data) => data));
  }

  Social(user: SocialUser): Observable<object> {
    return this.http
      .post('/api/SignIn/Social', user, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      })
      .pipe(map((data) => data));
  }

  SignOutApp(): Observable<object> {
    return this.http.get('/api/SignIn/SignOutApp').pipe(map((data) => data));
  }

  TwoFactorAuthenticatorSignIn(code: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('code', code);
    return this.http
      .get('/api/SignIn/TwoFactorAuthenticatorSignIn', {
        params: params,
        responseType: 'text'
      })
      .pipe((val) => val);
  }

  signOut(): Observable<object> {
    return this.http.get('/api/SignIn/SignOut').pipe(map((data) => data));
  }
}
